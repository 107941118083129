.adyen-checkout-payme-instructions {
  font-size: var(--adyen-sdk-text-body-font-size, 14px);
  text-align: center;
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
}
.adyen-checkout-payme-instructions__steps {
  list-style-position: inside;
  padding-inline-start: 0;
  margin: var(--adyen-sdk-spacer-070, 16px) 0;
  padding-bottom: var(--adyen-sdk-spacer-040, 8px);
}
.adyen-checkout__content-separator {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  white-space: nowrap;
  font-size: 13px;
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
}
.adyen-checkout__content-separator::after, .adyen-checkout__content-separator::before {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}
.adyen-checkout__content-separator::after {
  margin-left: var(--adyen-sdk-spacer-080, 20px);
}
.adyen-checkout__content-separator::before {
  margin-right: var(--adyen-sdk-spacer-080, 20px);
}